<template>
    <div class="profitPlan">
        <el-form label-position="right" label-width="80px">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="专家名称">
                        <el-select filterable v-model="hiddenUid" placeholder="请选择">
                            <el-option
                            v-for="item in expertsOptions"
                            :key="item.uid"
                            :label="item.nick_name"
                            :value="item.uid + '&' + item.period_id + '&' + item.period_no">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="期号">
                        <el-input disabled v-model="period_no"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <predicItem :data="matchList" @setRes="setRes"></predicItem>
            <predicItem :data="matchList" @setRes="setRes"></predicItem>
            <predicItem :data="matchList" @setRes="setRes"></predicItem>
            <predicItem :data="matchList" @setRes="setRes"></predicItem>
            <predicItem :data="matchList" @setRes="setRes"></predicItem>
            <predicItem :data="matchList" @setRes="setRes"></predicItem>
        </el-form>
        <p class="return">预计回报：{{return_rate}}</p>
        <div class="footer">
            <span @click="reset">取消</span>
            <span @click="save">确定</span>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>当前场次已有方案，是否继续添加？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '../../service/api';
import predicItem from "../../components/zjq/predictItem.vue";
export default {
    name: 'profitPlan',
    components: {
        predicItem
    },
    data () {
        return {
            dialogVisible: false,
            matchList: [],
            expertsOptions: [],
            hiddenUid: '',
            uid: '', // 专家
            period_id: '',
            period_no: '', // 期次
            matchObj: {}
        }
    },
    computed: {
        return_rate () {
            let rate = 1;
            const arr = Object.keys(this.matchObj);
            for (let i = 0; i < arr.length; i++) {
                const obj = this.matchObj[arr[i]]
                if (obj.firstOdds && obj.second_odds) {
                    if (parseFloat(obj.firstOdds) > parseFloat(obj.second_odds)) {
                        rate = rate * parseFloat(obj.firstOdds)
                    } else {
                        rate = rate * parseFloat(obj.second_odds)
                    }
                } else {
                    if (obj.firstOdds) {
                        rate = rate * parseFloat(obj.firstOdds)
                    } else {
                        rate = rate * parseFloat(obj.second_odds)
                    }
                }
            }
            return parseInt(rate * 100) / 100
        }
    },
    created () {
        this.initExpertsList()
    },
    watch: {
        hiddenUid () {
            const arr = this.hiddenUid.split('&');
            this.uid = arr[0];
            this.period_id = arr[1];
            this.period_no = arr[2];
            this.initMatchList()
        }
    },
    methods: {
        // 保存 TODO 校验参数
        async save () {
            this.dialogVisible = false;
            const matchArr = Object.keys(this.matchObj);
            if (matchArr.length > 6 || matchArr.length < 4) {
                this.$message.error('做多选择6场比赛，最少选择4场比赛');
                return
            }
            const param = {
                eid: this.uid,
                period_id: this.period_id,
                return_rate: this.return_rate
            }
            param.match_info_arr = [];
            for (let i = 0; i < matchArr.length; i++) {
                param.match_info_arr.push(this.matchObj[matchArr[i]])
            }
            param.return_rate = this.return_rate;
            const res = await api.profitPlanPublishlong(param)
            if (res.code === 0) {
                this.$message({
                    type: 'sucess',
                    message: '添加成功！'
                })
                window.parent.location.href = 'https://dpi.zjqvv.com/op-admin/jc-long/period'
            } else {
                alert(res.msg)
            }
        },
        // 重置状态
        reset () {
            this.matchObj = {}
        },
        setRes (obj) {
            this.matchObj[obj.match_id] = obj
        },
        // 初始化比赛列表
        async initMatchList () {
            this.reset();
            const res = await api.initProfitMatchListlong({
                plan_uid: this.uid
            })
            if (res.code === 0) {
                this.matchList = res.data || []
            } else {
                this.matchList = []
                alert(res.msg)
            }
        },
        async initExpertsList () {
            const res = await api.getExpertsListlong()
            if (res.code === 0) {
                this.expertsOptions = res.data;
            } else {
                alert(res.msg)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.profitPlan{
    padding: 20px;
    text-align: left;
    .return{
        text-align: center;
        font-size: 14px;
        color: red;
        margin-top: 50px;
    }
    .footer{
        width: 100%;
        margin-top: 40px;
        text-align: center;
        span{
            display: inline-block;
            margin-right: 20px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #eee;
            cursor: pointer;
        }
    }
}
</style>
